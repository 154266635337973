// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contentWrapper__e1a {
  width: calc(var(--ring-unit) * 75);
}

.providerLabel__f01 {
  color: var(--ring-secondary-color);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.providerLabel__f01 + .select__c21 {
  margin-top: calc(var(--ring-unit) / 2);
}

.select__c21 + .limitEditor__c76 {
  margin-top: calc(var(--ring-unit) * 1.5);
}

.modelsTable__a9d {
  padding-bottom: 4px;
}

.modelsTableColumnId__d82.modelsTableColumnId__d82 {
  min-width: fit-content;
  max-width: 350px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/configuration-page/license/ai-enterprise/dialogs/ai-settings-dialog/ai-settings-dialog.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;EAClC,wCAAwC;EACxC,2CAA2C;AAC7C;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".contentWrapper {\n  width: calc(var(--ring-unit) * 75);\n}\n\n.providerLabel {\n  color: var(--ring-secondary-color);\n  font-size: var(--ring-font-size-smaller);\n  line-height: var(--ring-line-height-lowest);\n}\n\n.providerLabel + .select {\n  margin-top: calc(var(--ring-unit) / 2);\n}\n\n.select + .limitEditor {\n  margin-top: calc(var(--ring-unit) * 1.5);\n}\n\n.modelsTable {\n  padding-bottom: 4px;\n}\n\n.modelsTableColumnId.modelsTableColumnId {\n  min-width: fit-content;\n  max-width: 350px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": `contentWrapper__e1a`,
	"providerLabel": `providerLabel__f01`,
	"select": `select__c21`,
	"limitEditor": `limitEditor__c76`,
	"modelsTable": `modelsTable__a9d`,
	"modelsTableColumnId": `modelsTableColumnId__d82`
};
export default ___CSS_LOADER_EXPORT___;
