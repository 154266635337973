import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo, useState } from 'react';
import { Content } from '@jetbrains/ring-ui/components/island/island';
import Panel from '@jetbrains/ring-ui/components/panel/panel';
import Button from '@jetbrains/ring-ui/components/button/button';
import Select from '@jetbrains/ring-ui/components/select/select';
import { isNil } from 'lodash';
import { DEFAULT_PRESET_VALUES } from '@app/pages/configuration-page/license/ai-enterprise/dialogs/ai-settings-dialog/default-preset-values';
import { AiLogo } from '@components/ai/ai-logo/ai-logo';
import RingFieldsList from '@components/form/ring-fields-list';
import { ConstituentDialog } from '../../../shared/constituent-dialog/constituent-dialog';
import { LimitAssigmentEditor } from '../../../shared/limit-assigment-editor/limit-assigment-editor';
import { OpenAIDetailedSettings } from './open-ai-detailed-settings';
import styles from './ai-settings-dialog.css';
const providerOptions = [
    {
        key: 'jetbrains',
        label: 'JetBrains AI',
        provider: { jetbrains: { id: 'jetbrains' } }
    },
    {
        key: 'openai',
        label: 'OpenAI',
        provider: {
            openai: {
                id: 'openai',
                ...DEFAULT_PRESET_VALUES.AZURE
            }
        }
    }
];
export function AiSettingsDialogBase(props) {
    var _a;
    const { onCloseAttempt, title, isLoading, onActivate, initialLimitUsage, limitsLocked, initialProvider } = props;
    const [usage, setUsage] = useState(initialLimitUsage !== null && initialLimitUsage !== void 0 ? initialLimitUsage : 'unlimited');
    const [selectedItem, setSelectedItem] = useState(() => {
        const initialItem = providerOptions.find(item => {
            return !!initialProvider && !!initialProvider[item.key];
        });
        return initialItem !== null && initialItem !== void 0 ? initialItem : providerOptions[0];
    });
    const [provider, setProvider] = useState(initialProvider || selectedItem.provider);
    const handleSelect = useCallback((item) => {
        if (item) {
            setSelectedItem(item);
            setProvider(item.provider);
        }
    }, []);
    const handleClose = useCallback(() => onCloseAttempt(undefined), [onCloseAttempt]);
    const handleActivate = useCallback(() => onActivate({ provider, assignedUsersLimit: usage === 'unlimited' ? undefined : usage }), [onActivate, provider, usage]);
    const handleOpenAIExtraSettingsChange = useCallback((openai) => setProvider({ openai }), []);
    const backendHasValidApiKey = Boolean(provider.openai && provider.openai.preset === ((_a = initialProvider === null || initialProvider === void 0 ? void 0 : initialProvider.openai) === null || _a === void 0 ? void 0 : _a.preset));
    const activateDisabled = useMemo(() => {
        if (isNil(usage) || (usage !== 'unlimited' && usage < 1)) {
            return true;
        }
        if (provider.openai) {
            const { openai } = provider;
            return (!openai.url ||
                ((openai === null || openai === void 0 ? void 0 : openai.preset) === 'AZURE' && openai.models.some(it => !it.deploymentName)) ||
                (!(openai === null || openai === void 0 ? void 0 : openai.apiKey) && !backendHasValidApiKey));
        }
        return !provider.jetbrains;
    }, [usage, provider, backendHasValidApiKey]);
    return (_jsxs(ConstituentDialog, { title: title, titleLogo: _jsx(AiLogo, {}), children: [_jsx(Content, { className: styles.contentWrapper, children: _jsxs(RingFieldsList, { children: [_jsx(Select, { selectedLabel: "Provider", className: styles.select, data: providerOptions, selected: selectedItem, onSelect: handleSelect }), provider.openai && (_jsx(OpenAIDetailedSettings, { value: provider.openai, hasValidApiKey: backendHasValidApiKey, onChange: handleOpenAIExtraSettingsChange })), _jsx(LimitAssigmentEditor, { toggleText: "Unlimited", limitNotice: _jsxs(_Fragment, { children: ["You can control your spending by setting a limit on the number of assigned users.", _jsx("br", {}), "Users who exceed this limit will have restricted access."] }), limit: usage, readonly: limitsLocked, onLimitChange: setUsage, className: styles.limitEditor })] }) }), _jsxs(Panel, { children: [_jsx(Button, { disabled: activateDisabled, loader: isLoading, onClick: handleActivate, primary: true, children: "Apply" }), _jsx(Button, { onClick: handleClose, children: "Cancel" })] })] }));
}
