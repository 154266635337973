import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useLayoutEffect, useState } from 'react';
import Toggle, { Size } from '@jetbrains/ring-ui/components/toggle/toggle';
import Input from '@jetbrains/ring-ui/components/input/input';
import classNames from 'classnames';
import { isNil } from 'lodash';
import styles from './limit-assigment-editor.css';
const DEFAULT_LIMIT = 250;
export function LimitAssigmentEditor(props) {
    const { readonly, limit, onLimitChange, toggleText, limitNotice, className } = props;
    const [inputValue, setInputValue] = useState(isNil(limit) || limit === 'unlimited' ? '' : limit.toString());
    const [unlimited, setUnlimited] = useState(limit === 'unlimited');
    // in case if value was changed from outside
    useLayoutEffect(() => {
        if (!isNil(limit) && limit === 'unlimited') {
            setUnlimited(limit === 'unlimited');
        }
        if (!isNil(limit) && limit !== 'unlimited') {
            setInputValue(limit.toString());
        }
    }, [limit]);
    const handleInputChange = useCallback((e) => {
        setInputValue(e.target.value);
        if (e.target.value.length) {
            onLimitChange(parseInt(e.target.value));
        }
        else {
            onLimitChange(undefined);
        }
    }, [onLimitChange]);
    const handleToggleChange = useCallback((e) => {
        setUnlimited(e.target.checked);
        if (e.target.checked) {
            onLimitChange('unlimited');
        }
        if (!e.target.checked) {
            if (inputValue.length) {
                onLimitChange(parseInt(inputValue));
            }
            else {
                onLimitChange(DEFAULT_LIMIT);
            }
        }
    }, [onLimitChange, inputValue, setUnlimited]);
    if (readonly) {
        return _jsx("div", { className: classNames(className), children: "Limits are defined by your active license" });
    }
    return (_jsxs("div", { className: className, children: [_jsx(Toggle, { checked: unlimited, onChange: handleToggleChange, size: Size.Size16, children: toggleText }), !unlimited && (_jsx(Input, { className: styles.input, name: "assignedUsersLimit", type: "number", min: 1, value: inputValue, onChange: handleInputChange })), _jsx("p", { className: styles.limitNotice, children: limitNotice })] }));
}
