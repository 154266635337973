import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from 'react';
import Select from '@jetbrains/ring-ui/components/select/select';
import Input, { Size as InputSize } from '@jetbrains/ring-ui/components/input/input';
import Button from '@jetbrains/ring-ui/components/button/button';
import Text from '@jetbrains/ring-ui/components/text/text';
import addIcon from '@jetbrains/icons/add';
import cancelIcon from '@jetbrains/icons/close';
import Icon from '@jetbrains/ring-ui/components/icon';
import { DEFAULT_PRESET_VALUES, OPTIONAL_MODELS } from '@app/pages/configuration-page/license/ai-enterprise/dialogs/ai-settings-dialog/default-preset-values';
import { Required } from '@components/form/important-asterisk';
import { FullWidthTableView } from '@components/tables/full-width-table-view';
import styles from './ai-settings-dialog.css';
const API_KEY_EXISTENCE_INDICATOR = '⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱⸱';
const aiPresetOptions = [
    {
        key: 'AZURE',
        label: 'Azure OpenAI'
    },
    {
        key: 'OPENAI',
        label: 'OpenAI Platform'
    }
];
export function OpenAIDetailedSettings({ value: initialValue, hasValidApiKey, onChange }) {
    const [formValue, updateFormValue] = useState(initialValue);
    const [selectedPresetItem, setSelectedPresetItem] = useState(aiPresetOptions.filter(it => it.key === formValue.preset)[0] || aiPresetOptions[0]);
    useEffect(() => onChange(formValue), [onChange, formValue]);
    const columns = useMemo(() => {
        const baseColumns = [
            {
                id: 'id',
                title: 'ID',
                className: styles.modelsTableColumnId
            },
            {
                id: 'modelName',
                title: 'Model name'
            }
        ];
        const removeOptionalModelColumn = [
            {
                id: 'removeOptionalModal',
                getValue(model) {
                    const isOptional = OPTIONAL_MODELS.some(it => it.id === model.id);
                    return isOptional ? (_jsx(Button, { text: true, inline: true, title: "Edit token", icon: cancelIcon, onClick: removeModel })) : null;
                    function removeModel() {
                        updateFormValue(prevState => ({
                            ...prevState,
                            models: prevState.models.filter(it => it.id !== model.id)
                        }));
                    }
                }
            }
        ];
        if (selectedPresetItem.key !== 'AZURE') {
            return baseColumns.concat(removeOptionalModelColumn);
        }
        const azureColumns = [
            {
                id: 'deploymentName',
                title: Required('Deployment name'),
                getValue(model) {
                    return (_jsx(Input, { value: model.deploymentName, onChange: updateDeploymentName, size: InputSize.FULL }));
                    function updateDeploymentName(evt) {
                        updateFormValue(prevState => ({
                            ...prevState,
                            models: withUpdatedModel(prevState, { ...model, deploymentName: evt.target.value })
                        }));
                    }
                }
            }
        ];
        return baseColumns.concat(azureColumns).concat(removeOptionalModelColumn);
    }, [selectedPresetItem]);
    const optionalModelsToAdd = useMemo(() => {
        if (!formValue.models.length) {
            return [];
        }
        return OPTIONAL_MODELS.filter(optionalModel => !formValue.models.some(model => model.id === optionalModel.id));
    }, [formValue]);
    return (_jsxs(_Fragment, { children: [_jsx(Select, { selectedLabel: "Preset", className: styles.select, data: aiPresetOptions, selected: selectedPresetItem, onSelect: updatePreset }), _jsx(Input, { value: formValue.url, label: Required('Endpoint'), onChange: updateUrl, size: InputSize.L }), _jsxs("div", { children: [_jsx(Input, { value: formValue.apiKey, label: hasValidApiKey ? 'API key' : Required('API key'), placeholder: hasValidApiKey ? API_KEY_EXISTENCE_INDICATOR : '', onChange: updateApiKey, type: "password", size: InputSize.L }), _jsx(FullWidthTableView, { tableWrapperClassName: styles.modelsTable, columns: columns, data: formValue.models, selectable: false, renderEmpty: () => (_jsxs(Text, { info: true, children: ["No models yet", ' ', _jsxs(Button, { text: true, onClick: () => updateFormValue(prevState => ({
                                        ...prevState,
                                        models: DEFAULT_PRESET_VALUES[formValue.preset].models
                                    })), children: [_jsx(Icon, { glyph: addIcon }), " Add"] })] })) }), !!optionalModelsToAdd.length && (_jsxs(Button, { text: true, inline: true, onClick: () => updateFormValue(prevState => ({
                            ...prevState,
                            models: prevState.models.concat(optionalModelsToAdd)
                        })), children: [_jsx(Icon, { glyph: addIcon }), ' ', optionalModelsToAdd.length === 1 ? 'Add optional model' : 'Add optional models'] }))] })] }));
    function withUpdatedModel(provider, model) {
        return provider.models.map(it => (it.id === (model === null || model === void 0 ? void 0 : model.id) ? model : it));
    }
    function updateUrl(evt) {
        updateFormValue(prevState => ({ ...prevState, url: evt.target.value }));
    }
    function updateApiKey(evt) {
        updateFormValue(prevState => ({ ...prevState, apiKey: evt.target.value }));
    }
    function updatePreset(preset) {
        if (preset) {
            setSelectedPresetItem(preset);
            updateFormValue(prevState => ({ ...prevState, ...DEFAULT_PRESET_VALUES[preset.key] }));
        }
    }
}
