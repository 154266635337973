export const DEFAULT_PRESET_VALUES = {
    AZURE: {
        preset: 'AZURE',
        url: '',
        models: [
            {
                id: 'openai-chat-gpt',
                modelName: 'gpt-35-turbo',
                deploymentName: '',
                tokenLimit: 16385
            },
            {
                id: 'openai-gpt-4',
                modelName: 'gpt-4',
                deploymentName: '',
                tokenLimit: 128000
            }
        ]
    },
    OPENAI: {
        preset: 'OPENAI',
        url: 'https://api.openai.com/v1',
        models: [
            {
                id: 'openai-chat-gpt',
                modelName: 'gpt-3.5-turbo',
                deploymentName: '',
                tokenLimit: 16385
            },
            {
                id: 'openai-gpt-4',
                modelName: 'gpt-4',
                deploymentName: '',
                tokenLimit: 128000
            }
        ]
    }
};
export const OPTIONAL_MODELS = [
    {
        id: 'openai-gpt-4o',
        modelName: 'gpt-4o',
        deploymentName: '',
        tokenLimit: 128000
    }
];
